<template>
  <el-card
    @click.native.stop="$emit('handleSelect')"
    @dblclick.native="$emit('handleEdit')"
  >
    <div
      style="display: flex; position: relative; align-items: center; height: 100%"
    >
      <div
        style="width: 10px; height: 10px; border-radius: 5px; background-color: #0078e7; position: absolute; top:10px; right: 10px"
        v-if="isTemplateModified && showOverlay"
      ></div>
      <div
        style="display: flex; flex-direction: column;width: 100%; justify-content: center"
      >
        <img
          :src="getCardSvg(task_template.image_path)"
          alt="taskLibraryImg"
          height="120px"
          width="100%"
          style="padding-top: 10px"
        />
        <div
          style="display: flex; justify-content: center;margin-top: 10px;padding: 0 10px;"
        >
          <div
            style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box; -webkit-line-clamp: 2;-webkit-box-orient: vertical;text-align: center; font-size: 14px; font-weight: bold; padding-bottom: 2px"
          >
            {{ task_template.template_name }}
          </div>
        </div>
      </div>
      <div class="cardOverlay" v-if="showOverlay">
        <div
          style="display: flex; flex-direction: column; height: 100%; padding: 0 20px;justify-content: space-between; position:relative;"
        >
          <el-popover
            width="150"
            trigger="hover"
            placement="right-end"
            v-if="isTemplateModified && showOverlay"
          >
            <span style="word-break: break-word">{{
              __("Some changes requires republishing")
            }}</span>
            <div
              style="width: 10px; height: 10px; border-radius: 5px; background-color: #0078e7; position: absolute; top:10px; right: 10px"
              slot="reference"
            ></div>
          </el-popover>
          <div
            style="display: flex;flex-direction: column;padding: 20px; justify-content: space-between;max-height: 100px"
          >
            <div style="display: flex">
              <img :src="require(`@/assets/icons/icon-publisher.svg`)" /><span
                class="details"
                >{{ task_template.publisher }}</span
              >
            </div>
            <div style="display: flex">
              <img :src="require(`@/assets/icons/icon-import.svg`)" />
              <span class="details">{{
                abbreviate(task_template.import_count)
              }}</span>
            </div>
            <div v-if="task_template.tags" style="display: flex">
              <img :src="require(`@/assets/icons/icon-tags.svg`)" />
              <span class="details">{{ tags }}</span>
            </div>
          </div>
          <div style="display: flex;flex-direction: column;padding: 20px 0">
            <el-button @click="openCallFlowEditor" class="viewCanvasBtn">
              {{
                this.userHasSPScopeToAc
                  ? selectedAccountId === task_template.task.data.ac_id
                    ? (task_template.is_system && !$auth.check("system")) ||
                      !canWrite()
                      ? previewCanvasLabel()
                      : editCanvasLabel()
                    : previewCanvasLabel()
                  : previewCanvasLabel()
              }}
            </el-button>
            <el-button
              @click="$emit('create-task-from-template', task_template)"
              class="createTaskBtn"
              :disabled="!can('build.tasks.write')"
            >
              {{ __("Create Task") }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      canvasActions: {
        preview: __("Preview Canvas"),
        edit: __("Edit Canvas")
      }
    };
  },
  props: {
    task_template: {
      type: Object,
      default() {
        return {
          task: { data: {} },
          image_path: "blank_canvas_image.svg",
          template_name: __("Blank Canvas")
        };
      }
    },
    showOverlay: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    tags() {
      if (!_.isEmpty(this.task_template.tags)) {
        let tags = this.task_template.tags.split(",", 4);
        return tags.join(", ");
      } else {
        return "";
      }
    },
    isTemplateModified() {
      return (
        this.task_template.task &&
        !!this.task_template.task.data.version_in_use_modified
      );
    },

    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapGetters("app", {
      userHasSPScopeToAc: "userHasSPScopeToAc"
    })
  },
  methods: {
    getTagsArray(tags) {
      if (tags) {
        return tags.length > 0 ? tags.split(",", 4) : [];
      }
    },
    openCallFlowEditor() {
      this.$router.push({
        name: "callflow",
        params: { task_id: this.task_template.task.data.task_id }
      });
    },
    editCanvasLabel() {
      return this.canvasActions.edit;
    },
    previewCanvasLabel() {
      return this.canvasActions.preview;
    },
    getCardSvg(image_path) {
      if (image_path) {
        return require("@/assets/images/taskImages/" + image_path);
      }
      return require(`@/assets/images/taskImages/task_image_placeholder.svg`);
    },
    abbreviate(count) {
      let abbreviate = require("number-abbreviate");
      const cnt = abbreviate(count);
      return /\D/.test(cnt) ? cnt + "+" : cnt;
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";

.cardContainer {
  user-select: none;
}

.cardContainer.selected {
  border: var(--theme-color) 2px solid;
}
.el-card {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin: 10px;
  height: 270px;
  width: calc(20% - 22px);
  max-width: 239px;
  min-width: 232px;
  display: flex;
  border: 1px solid transparent;
  border-radius: 5px;
  align-items: center;

  &.selected {
    border: 1px solid $content-theme-color;
  }

  &:hover {
    .cardOverlay {
      opacity: 1;
      transition: opacity 0.5s ease;
    }
  }

  ::v-deep .el-card__body {
    display: flex;
    padding: 10px 0;
    width: 100%;
    height: 100%;
    flex-grow: 0;
    flex-direction: column;
  }
}

.cardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 270px;
  background: white;
  opacity: 0;

  &.locked {
    background: #f5f5f5;
  }

  transition: opacity 0.5s ease;
}

.tag {
  background: #f5f5f5;
  padding: 5px;
  color: #a0a8b5;
  margin: 3px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  border: #a0a8b5 1px solid;
}

.viewCanvasBtn {
  width: 100%;
  color: $content-theme-color;
  border: $content-theme-color solid 1px;
  transition: all 0.5s ease;
  margin: 0 !important;
  &:hover {
    background: $content-theme-color;
    color: white;
    transition: all 0.5s ease;
  }
}

.createTaskBtn {
  width: 100%;
  margin: 10px 0 0 0 !important;
  border: $content-theme-color 1px solid;
  color: white;
  background: $content-theme-color;
  &:hover {
    background: $content-theme-hover-color;
    color: white;
  }
}

.details {
  padding-left: 10px;
  padding-bottom: 5px;
  word-break: break-word;
}
</style>
