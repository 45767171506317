<template>
  <div
    style="max-height: calc(100vh - 34px); overflow-y: scroll"
    v-scroll:debounce="{ fn: onScroll, debounce: 10 }"
  >
    <el-row type="flex">
      <el-col :span="13" :offset="4">
        <start-with-blank
          @create-blank-task="$emit('create-blank-task', $event)"
        ></start-with-blank>
      </el-col>
      <el-col
        :span="3"
        style="display: flex; justify-content: flex-end;padding-top: 30px"
        class="importUpload"
      >
        <el-upload
          :headers="AcHeaders"
          :action="getUploadCTFUrl"
          :data="formData"
          :show-file-list="true"
          :on-success="handleSuccess"
          :on-error="handleError"
          :limit="1"
          :multiple="false"
          accept=".ctf"
          ref="import"
          :disabled="!permit('TASK IMPORT')"
        >
          <el-tooltip
            effect="dark"
            placement="bottom"
            :content="__('You do not have permission to manage this item')"
            :disabled="permit('TASK IMPORT')"
          >
            <span class="wrapper">
              <el-button
                class="editBtn"
                :loading="isLoading"
                :disabled="!permit('TASK IMPORT')"
                >{{ __("Import Task") }}</el-button
              >
            </span>
          </el-tooltip>
        </el-upload></el-col
      >
      <el-col :span="4"></el-col>
    </el-row>

    <el-row type="flex" class="bg-white">
      <el-col :span="24" style="padding-top: 20px">
        <start-with-template
          v-if="id === -1"
          @create-from-template="handleCreateFromTemplate"
        ></start-with-template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import StartWithBlank from "../create-task/StartWithBlank";
import StartWithTemplate from "../create-task/StartWithTemplate";
import BaseUploader from "@/components/uploaders/BaseUploader";

export default {
  name: "CreateTaskOptions",
  mixins: [BaseUploader],
  components: {
    StartWithBlank,
    StartWithTemplate
  },
  props: {
    clearFiles: {
      required: false,
      default: false,
      type: Boolean
    },
    id: {
      required: false,
      default: -1,
      type: Number
    }
  },
  methods: {
    handleCreateFromTemplate(template) {
      this.$emit("create-from-template", template);
    },
    onScroll(e, v) {
      let tagsMenu = document.getElementById("tags-menu");
      if (v.scrollTop > 50) {
        tagsMenu.className = "el-menu-vertical show el-col el-col-4";
      } else {
        tagsMenu.className = "el-menu-vertical hide el-col el-col-4";
      }
    },
    permit(operation) {
      return this.can(operation) && this.canWrite();
    },
    clearUploadedFiles() {
      this.$refs.import.clearFiles();
    }
  },
  watch: {
    clearFiles(val) {
      if (val) {
        this.clearUploadedFiles();
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/pagination-toolbar.scss";

::v-deep .flex-space-between {
  display: flex;
  justify-content: space-between;
}

::v-deep .mt-2 {
  margin-top: 0.5rem;
}

::v-deep .mt-8 {
  margin-top: 2rem;
}

::v-deep .btn-dark {
  background-color: #222933;
  border-color: #222933;
}

.bg-white {
  background-color: white;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(160, 168, 181, 0.5);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a8b5;
}

.editBtn {
  width: 150px;
}

.importUpload {
  div {
    display: flex;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
  }

  .el-button:focus {
    background: var(--theme-color);
    border: none;
    color: white;
  }
}
</style>
