<template>
  <div>
    <el-row type="flex">
      <el-col :span="4">
        <div
          class="el-menu-vertical hide"
          id="tags-menu"
          style="position: -webkit-sticky; position: sticky; top: 0; z-index: 2000; background-color: white;padding-top: 100px"
        >
          <el-menu
            default-active="1-1"
            :default-openeds="['1', '2', '3']"
            style="width: 100%"
          >
            <el-submenu index="1">
              <template slot="title">
                <span class="tag-title">{{ __("Tags") }}</span>
              </template>
              <el-menu-item index="1-1" @click="searchPrefill('')"
                >{{ __("All") }}
              </el-menu-item>
              <el-menu-item index="1-2" @click="searchPrefill('security')"
                >{{ __("Security") }}
              </el-menu-item>
              <el-menu-item index="1-3" @click="searchPrefill('smart routing')"
                >{{ __("Smart routing") }}
              </el-menu-item>
              <el-menu-item index="1-4" @click="searchPrefill('surveys')"
                >{{ __("Surveys") }}
              </el-menu-item>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <span class="tag-title">{{ __("Industries") }}</span>
              </template>
              <el-menu-item index="2-1" @click="searchPrefill('hospitality')"
                >{{ __("Hospitality") }}
              </el-menu-item>
              <el-menu-item index="2-2" @click="searchPrefill('retail')"
                >{{ __("Retail") }}
              </el-menu-item>
              <el-menu-item index="2-3" @click="searchPrefill('marketing')"
                >{{ __("Marketing") }}
              </el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <span class="tag-title">{{ __("Publishers") }}</span>
              </template>
              <el-menu-item index="3-1" @click="searchPrefill(`Inference`)"
                >{{ __("Inference") }}
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </el-col>
      <el-col :span="16">
        <div
          style="padding-top:20px;position: -webkit-sticky; position: sticky; top: 0; z-index: 2000; background-color: white;"
        >
          <div>
            <div class="primary-title">
              {{ __("Select from the task library") }}
            </div>
            <div class="comments mt-2">
              {{
                __("Select a ready-built call flow and configure to your needs")
              }}
            </div>
          </div>
          <el-form class="mt-8">
            <PaginationToolbar
              :sort-by-options="sortByOptions"
              :content-api="contentAPI"
              @success="$emit('replaceContent', $event)"
              ref="pagination"
              :list-changed-event-name="`task-library-list`"
              :search-prefill="tagFilter"
              :page-sizes="[15, 30, 45, 60]"
              :page-size="15"
              v-if="can('TASK COPY FROM TEMPLATE')"
            >
            </PaginationToolbar>
          </el-form>
        </div>
        <div v-loading="loading" v-if="can('TASK COPY FROM TEMPLATE')">
          <div class="contentCardsContainer">
            <el-row
              type="flex"
              justify="start"
              class="list-cards"
              v-loading="loading"
            >
              <TaskTemplateCard
                :task_template="template"
                v-for="(template, index) in templates"
                :key="index"
                @click.native.stop="handleTemplateSelect(template)"
              ></TaskTemplateCard>
            </el-row>
          </div>
        </div>
        <div v-else>
          <p>
            {{ __("This feature is not provisioned in your business plan") }}
          </p>
        </div>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
</template>

<script>
import TaskTemplateCard from "./components/TaskTemplateCard";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { mapActions, mapState } from "vuex";
import _ from "lodash";

export default {
  mixins: [BaseContent],
  name: "StartWithTemplate",
  components: {
    PaginationToolbar,
    TaskTemplateCard
  },
  data() {
    return {
      sortByColumns: ["template_name", "template_id"],
      tagFilter: ""
    };
  },
  computed: {
    ...mapState("tasks", {
      templates: state => state.templates,
      loading: state => state.loading
    })
  },
  methods: {
    ...mapActions("tasklibrary", {
      decodeTemplate: "decodeTemplate"
    }),
    ...mapActions("tasks", {
      contentAPI: "getTemplates"
    }),
    searchPrefill(tag) {
      this.tagFilter = tag;
    },
    async handleTemplateSelect(template) {
      await this.decodeTemplate(template.template_id).then(data => {
        template = _.merge({}, template, data);
        this.$emit("create-from-template", template);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/typography.scss";
@import "~@/styles/pagination-toolbar.scss";

.list-cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  padding: 0 10px;
}

.box-shadow:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 1px;
  z-index: -1;
  transform: scale(0.99);
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.15);
}

.el-menu-vertical {
  transition: all 0.5s;
}

.hide {
  opacity: 0;
  visibility: hidden;
}

.show {
  opacity: 1;
  width: 100%;
}

::v-deep .el-menu {
  border-right: none;
}

.tag-title {
  font-size: 1.1em;
  font-weight: 700;
}
</style>
