<template>
  <div>
    <div>
      <div class="primary-title">
        {{ __("Start with a blank canvas") }}
      </div>
      <div class="comments mt-2">
        {{ __("Start building a task from scratch using drag & drop") }}
      </div>
    </div>
    <div class="mt-8">
      <TaskTemplateCard
        @click.native.stop="$emit('create-blank-task', $event)"
      ></TaskTemplateCard>
    </div>
  </div>
</template>

<script>
import TaskTemplateCard from "./components/TaskTemplateCard";

export default {
  name: "EditTask",
  components: {
    TaskTemplateCard
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/typography.scss";
</style>
